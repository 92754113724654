import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Iconify from '../iconify';

// ----------------------------------------------------------------------
type Props = TextFieldProps & {
  name: string;
  /**
   * @default 'standard'
   */
  variant?: TextFieldProps['variant'];
  enabledStatusIcon?: boolean;
  readOnly?: boolean;
};

export default function RHFTextField({
  name,
  variant,
  helperText,
  type,
  inputProps,
  autoCapitalize = 'none',
  enabledStatusIcon,
  readOnly,
  color,
  InputProps,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          variant={variant}
          fullWidth
          type={type}
          color={color}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            autoCapitalize,
            readOnly,
            ...inputProps,
          }}
          InputProps={{
            ...(enabledStatusIcon && {
              endAdornment: (
                <InputAdornment position="end">
                  <Iconify
                    icon={
                      (!error && field.value && 'ic:round-check-circle') ||
                      (error && field.value && 'ph:warning-circle-fill') ||
                      (error && !field.value && 'ph:warning-circle-fill')
                    }
                    color={error ? 'error.main' : 'primary.main'}
                  />
                </InputAdornment>
              ),
            }),
            ...(color && {
              sx: {
                '&::before': {
                  borderBottomColor: `${color}.main`,
                },
              },
            }),
            ...InputProps,
          }}
          {...other}
        />
      )}
    />
  );
}
