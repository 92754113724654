'use client';

import { useCallback, useState } from 'react';
// form
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormProvider from '@/components/hook-form/form-provider';
import RHFTextField from '@/components/hook-form/rhf-text-field';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
};

export default function LoginView() {
  const { login } = useAuthContext();

  const [errorMsg, setErrorMsg] = useState('');

  const password = useBoolean();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required('correo electronico es requerido')
      .email('El correo electrónico debe ser una dirección de correo electrónico válida.'),
    password: Yup.string().required('se requiere contraseña'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = useCallback(
    async (data: FormValuesProps) => {
      try {
        await login(data.email, data.password);
      } catch (error) {
        console.error(error);
        reset();
        setErrorMsg(typeof error === 'string' ? error : error.message);
      }
    },
    [login, reset]
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4} sx={{ textAlign: 'left' }}>
        {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        <RHFTextField
          name="email"
          autoCapitalize="none"
          label="Correo Electrónico"
          enabledStatusIcon
        />

        <RHFTextField
          name="password"
          label="Contraseña"
          autoCapitalize="none"
          type={password.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end" tabIndex={-1}>
                  <Iconify icon={password.value ? 'el:eye-open' : 'el:eye-close'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Link
          variant="body2"
          color="inherit"
          underline="hover"
          component={RouterLink}
          href={paths.auth.forgot}
          sx={{ alignSelf: 'flex-end', fontWeight: 600 }}
        >
          ¿Olvidaste tu contraseña?
        </Link>

        <Stack spacing={1.5}>
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            fullWidth
            loading={isSubmitting}
          >
            INICIAR SESIÓN
          </LoadingButton>

          <Button component={RouterLink} href={paths.starter} variant="text" fullWidth>
            ¿No tienes una cuenta? Regístrate
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );

  return <>{renderForm}</>;
}
